import { configureStore } from '@reduxjs/toolkit';
import uiReducer from './ui-slice';
import environment from 'common/environment';
import { setupListeners } from '@reduxjs/toolkit/query';
import { contentApi } from 'common/api/content-api';
import { hotelsApi } from 'common/api/hotels-api';

const store = configureStore({
    reducer: {
        ui: uiReducer,
        [contentApi.reducerPath]: contentApi.reducer,
        [hotelsApi.reducerPath]: hotelsApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(contentApi.middleware)
            .concat(hotelsApi.middleware),
    devTools: environment.label === 'dev'
});

setupListeners(store.dispatch);

export default store;
