import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from 'common/environment';
import { createContentPath } from 'common/helpers/endpoint-helpers';
import translator from 'common/i18n';
import { prepareParams } from 'common/helpers/param-helpers';
import { ACCOUNT, DEFAULT_LANG } from 'common/constants';

export const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchBaseQuery({ baseUrl: `//${environment.api}` }),
    endpoints: (builder) => ({
        /**
         * type lang id content API
         * request a content item by type, language and ID
         *
         * @param {string} type - (required) content type (eg PHOTO)
         * @param {string} id - (required) content item ID
         * @param {object} params - (optional) additional params
         */
        typeLangIdRequest: builder.query({
            query: ({ type, id, params }) =>
                createContentPath(type, params, translator.language, id),
            transformResponse: (response) => {
                if (response?.items || response?.content) {
                    // Check if a playlist (has an items array) and re-org images
                    if (
                        (response?.items && Array.isArray(response?.items)) ||
                        (response?.content[0]?.items &&
                            Array.isArray(response?.content[0]?.items))
                    ) {
                        const items = response.items
                            ? response.items
                            : response?.content[0].items;

                        items.forEach((item) => {
                            if (item.response) {
                                let onDemandUrl;

                                if (item.response.customContent?.onDemandUrl) {
                                    onDemandUrl =
                                        item.response.customContent.onDemandUrl;
                                } else if (
                                    item.response.promoItem?.onDemandUrl
                                ) {
                                    onDemandUrl =
                                        item.response.promoItem.onDemandUrl;
                                }

                                item.response = {
                                    ...item.response,
                                    trips:
                                        item.response.references.length &&
                                        item.response.references[0].type ===
                                            'PLAYLIST' &&
                                        item.response.references,
                                    onDemandUrl
                                };
                            }
                        });
                    }
                }

                return response;
            }
        }),

        /**
         * content type API
         * request content by type
         *
         * @param {string} type - (required) content type (eg PHOTO)
         * @param {object} params - (optional) additional params
         */
        contentType: builder.query({
            query: ({ type, params, lang }) => {
                return `/content/${ACCOUNT}/${type}/${
                    lang ? lang : DEFAULT_LANG
                }${prepareParams(params)}&detail=DETAILED`;
            },
            transformResponse: (response) => {
                return response.content;
            }
        }),
        /**
         * Gets a list of videos from Content API
         */
        getContentsTypeIds: builder.query({
            query: ({ type, params }) => {
                return `/content/${ACCOUNT}/all/${
                    translator.language
                }${prepareParams(params)}&detail=DETAILED`;
            }
        }),
        tripsType: builder.query({
            queryFn: async (
                { type, id, params },
                _queryApi,
                _extraOptions,
                fetchWithBQ
            ) => {
                const tripList = await fetchWithBQ(
                    createContentPath(type, params, translator.language, id)
                );

                if (tripList.error) {
                    return { error: tripList.error };
                }

                const tripItemsPromises = await Promise.all(
                    tripList?.data?.references.map(async (item) => {
                        if (item.type !== 'PLAYLIST') {
                            return;
                        }

                        const { data } = await fetchWithBQ(
                            createContentPath(
                                item.type,
                                { fullObjectResponse: true },
                                translator.language,
                                item.id
                            )
                        );

                        return { ...item, ...data };
                    })
                );

                return tripList
                    ? { data: tripItemsPromises }
                    : { error: tripItemsPromises.error };
            }
        })
    })
});

export const createContentTypesParams = (items, type) =>
    items.map((id) => `${type}:${id}`).join(',');

export const {
    useTypeLangIdRequestQuery,
    useContentTypeQuery,
    useTripsTypeQuery,
    useGetContentsTypeIdsQuery
} = contentApi;
