//<PREACT_DEBUG>
import { addAllWidgets } from './widgetInitialiser';
import { setupPage } from './page';
import { setupLazyImages } from './lazy-load';
import { watchProgress } from 'common/helpers/videos';

/**
 * Fires as soon as the DOM is ready, put anything you want to happen
 * IMMEDIATELY and on every page, here.
 */
function domReady() {
    addAllWidgets();
    setupLazyImages();
    setupPage();
    watchProgress();
}

switch (document.readyState) {
    case 'interactive':
    case 'complete':
        domReady();
        break;
    default:
        document.addEventListener('DOMContentLoaded', () => {
            domReady();
        });
}
