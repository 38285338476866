const OBSERVER_CONFIG = {
    root: null, // default to browser viewport
    rootMargin: '0px',
    threshold: 0.025
};

const OBSERVABLE_SELECTOR = '.js-lazy-load';

class LazyLoad {
    /**
     * Class that loads in images when they become active
     * upon entering the viewport
     *
     * @param {HTMLElement} container the container element of the items that should be observed
     * @class
     */
    constructor(container) {
        const observableContainers = [].slice.call(
            container.querySelectorAll(OBSERVABLE_SELECTOR)
        );

        // get items unobserved and apply the listeners
        const uninitiatedObservables =
            this.getUnobservedElements(observableContainers);
        this.setObservableListener(uninitiatedObservables);
    }

    /**
     * Filters the observableContainer list so the items being observed have not been observed before
     * and are the correct markup elements for observing
     *
     * @param {HTMLElement[]} observableContainers - the matching elements that are meant to be observed
     * @returns {Array} Filtered array of observable elements
     */
    getUnobservedElements(observableContainers) {
        return observableContainers.filter((observableEl) => {
            return (
                observableEl &&
                observableEl.getAttribute('data-picture-in-view') !== 'true'
            );
        });
    }

    /**
     * Listens for elements to become visible within the viewport
     *
     * @param {HTMLElement[]} observableContainers - the matching elements to apply observer listeners to
     */
    setObservableListener(observableContainers) {
        this.observer = new IntersectionObserver(_onActivate, OBSERVER_CONFIG);
        observableContainers.forEach((element) => {
            this.observer.observe(element);
        });
    }
}

/**
 * Callback function from intersection observer
 *
 * `getAttribute` has been used deliberately as it tested faster than
 * `element.dataset.attrName`.
 *
 * @param  {object[]} entries An Array of IntersectionObserver elements
 */
function _onActivate(entries) {
    entries.forEach((contentItem) => {
        let imgContainer = contentItem.target;
        if (!imgContainer) {
            return;
        }

        // check if the intersection observer's already run on this element
        let hasIntersectedBefore = imgContainer.getAttribute(
            'data-picture-in-view'
        );
        if (
            contentItem.isIntersecting === false ||
            hasIntersectedBefore === 'true'
        ) {
            return;
        }

        // Picture is now in view so add this attr to stop it rendering again.
        imgContainer.setAttribute('data-picture-in-view', 'true');

        // get the picture element/image markup from the comment and add it to the container
        const imageComment = _getFirstCommentNodeChild(imgContainer);
        if (imageComment) {
            imgContainer.insertAdjacentHTML(
                'beforeend',
                imageComment.textContent.trim()
            );
        }
        // fade in the image (if needed - only js-faded-image marked elements need this)
        let fadedImage = imgContainer.querySelector('.js-faded-image');
        if (fadedImage) {
            if (fadedImage.complete) {
                _fadeElementIn(fadedImage, imgContainer);
            } else {
                // only add load listener if the image hasn't been loaded already
                fadedImage.addEventListener('load', () =>
                    _fadeElementIn(fadedImage, imgContainer)
                );
            }
        }
    });
}

/**
 * @param  {HTMLElement} fadedImage - The faded image element
 * @param  {HTMLElement} imgContainer - The image container element
 */
function _fadeElementIn(fadedImage, imgContainer) {
    fadedImage.classList.add('is-loaded');
    imgContainer.classList.add('is-loaded');
}

/**
 * Returns the first comment node child of an element
 *
 * @param  {HTMLElement} element - the html element to search the child nodes for
 * @returns {(Comment|null)}      - the 1st comment node found, or null
 */
function _getFirstCommentNodeChild(element) {
    for (let i = 0; i < element.childNodes.length; i++) {
        if (element.childNodes[i].nodeType === Node.COMMENT_NODE) {
            return element.childNodes[i];
        }
    }
    return null;
}

/**
 * Queries the given element for lazy images then kicks of a new LazyLoad
 * instance for each found container.
 *
 * @param {Element} [container] - The element to look in
 */
function setupLazyImages(container = document) {
    const containers = container.querySelectorAll('.js-lazy-load-images');
    for (let element of containers) {
        new LazyLoad(element);
    }
}

export { LazyLoad, setupLazyImages };
