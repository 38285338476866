import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import environment from 'common/environment';
import translator from 'common/i18n';
import { getCountryByLang } from 'common/helpers/misc';
import { shuffleArray } from 'common/helpers/shuffle-array';

const country = getCountryByLang(translator.language);

export const hotelsApi = createApi({
    reducerPath: 'hotelsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${environment.hotelsApi}`
    }),
    endpoints: (builder) => ({
        hotels: builder.query({
            query: ({ hotels }) =>
                `?ids=${Array.isArray(hotels) ? hotels.join(',') : hotels}&country=${country}&pos=expedia`,
            transformResponse: (response, meta, arg) => {
                let ids;
                let arrangedHotels;

                // Check if arg.hotels is an array or a string and convert to array if necessary
                if (Array.isArray(arg.hotels)) {
                    ids = arg.hotels;
                } else if (typeof arg.hotels === 'string') {
                    ids = arg.hotels.split(',')
                } else {
                    throw new TypeError('arg.hotels should be either a string or an array');
                }

                if(arg.randomizeHotels) {
                    // shuffle order if checkbox is ticked 
                    arrangedHotels = shuffleArray(response.hotels)
                } else {
                    // Sort the response hotels according to the order of ids array
                    arrangedHotels = ids.map(id => response.hotels.find(hotel => hotel.id === id));
                }

                // Filter out undefined or null hotels
                arrangedHotels = arrangedHotels.filter(hotel => hotel);

                // Return the desired hotels order with additional check-in and check-out dates
                return arrangedHotels.map((hotel) => {
                    
                    return {
                        ...hotel,
                        checkInDate: response.checkInDate,
                        checkOutDate: response.checkOutDate
                    };
                });
            }
        })
    })
});

export const { useHotelsQuery } = hotelsApi;
