import translator from 'common/i18n';
import { checkIsIOS } from 'common/helpers/misc';
import { CLASSES } from 'common/constants';

export const getVideoDuration = (seconds) => {
    let minutes = Math.round(seconds / 60);
    if (minutes === 0) {
        minutes = 1;
    }
    return `${minutes}`;
};

/**
 * Checks if a video as a specific tag
 *
 * @param {object} video - video object that includes the tags
 * @param {string} tagName - tag name to check for
 * @returns {boolean} whether tag exists
 */
export const hasVideoTag = (video, tagName) =>
    video?.tags && video.tags.some((tag) => tag.label === tagName);

/**
 * Checks localStorage to see what the watch progress is of a video id
 *
 * @param {HTMLElement} [element]       - optional override (instead of querying the whole document)
 */
export function watchProgress(element = document) {
    const isIOS = checkIsIOS(window.navigator);
    const labels = [].slice.call(
        element.querySelectorAll('.js-watch-progress')
    );

    labels.forEach((label) => {
        const progressBar = label.dataset.progressBar === 'true';
        const videoId = +label.dataset.videoId;
        const localProgress = +localStorage.getItem(
            `video-progress-${videoId}`
        );

        if (progressBar) {
            if (localProgress) {
                label.children[0].style.width = `${localProgress}%`;
            } else {
                label.parentElement.classList.add(CLASSES.U_HIDE);
            }
        } else {
            const progressLabel = getWatchProgressLabel(localProgress, isIOS);
            label.textContent = progressLabel;
        }
    });
}

/**
 * Get a progress label based on video progress
 * If device is iOS continue watching functionality is disabled: always display as standard 'Watch Now' label
 *
 * @param {number} progress - percentage value of viewing progress for given video
 * @param {boolean} isIOS - whether the device is iOS
 * @returns {string} translatable progress label
 */
export const getWatchProgressLabel = (progress, isIOS = false) => {
    if (!progress || isIOS) {
        return translator.lookup('label.video.watch-prompt');
    } else if (progress && progress < 100) {
        return translator.lookup('label.video.continue-prompt');
    }
    return translator.lookup('label.video.rewatch-prompt');
};

/**
 * Return correct video url based on tag
 *
 * @param {object} video - video object that includes the tags
 * @param {string} tagName - tag name to check for
 * @returns {string} url
 */
export const getVideoURL = (video, tagName) => {
    return hasVideoTag(video, tagName)
        ? `/video/roadtrip/${video.id}`
        : `/video/${video.id}`;
};
