/**
 * Turns Key: Value object into ?key=value query string.
 *
 * @param  {object} params - keys define param name and value the value
 * @returns {string} the query param string
 */
export function prepareParams(params = {}) {
    const paramsArray = [];

    for (let key in params) {
        // eslint-disable-next-line no-prototype-builtins
        if (params.hasOwnProperty(key)) {
            let value = params[key];
            if (typeof value === 'undefined') {
                console.warn(
                    `Value for '${key}' in object 'params' was undefined. The key '${key}' has been omitted from the query string. Please be explicit with values, e.g. boolean or empty string`
                );
                continue;
            }
            const keyValuePair = [key, encodeURIComponent(value)];
            paramsArray.push(keyValuePair.join('='));
        }
    }

    return paramsArray.length > 0 ? '?' + paramsArray.join('&') : '';
}
