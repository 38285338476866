import { createSlice } from '@reduxjs/toolkit';
import { BREAKPOINTS, COOKIE_INTRO, COOKIE_MOOD } from 'common/constants';
import { dataLayerPush } from 'common/helpers/datalayer-push';

const deviceWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
);
const deviceHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
);

/**
 * @constant {Slice} uiSlice - Creates a slice of the redux store to handle
 * various bits of ui state
 */
export const uiSlice = createSlice({
    name: 'ui',
    /**
     * @constant {object} initialState - Setup the starting values for the store
     */
    initialState: {
        isMobile: window.innerWidth < BREAKPOINTS.MOBILE,
        isDesktop: window.innerWidth > BREAKPOINTS.TABLET,
        isDesktopSmall: window.innerWidth > BREAKPOINTS.DESKTOP_SMALL,
        orientation: deviceWidth > deviceHeight ? 'Landscape' : 'Portrait',
        moodIndex: localStorage.getItem( COOKIE_MOOD ) ? JSON.parse(localStorage.getItem( COOKIE_MOOD )) : 0,
        selectedPromo: {},
        mapMarkers: [],
        mapIsOpen: false,
        interactiveMap: null,
        interactiveMapActivePin: null,
        isNavSticky: false,
        isAtPageBottom: false,
        footerHeight: 0,
        pageTrackingCampaignName: '',
        scrollPosition: document.querySelector( 'html' ).scrollTop,
        skipIntro: localStorage.getItem( COOKIE_INTRO ) === 'true'
    },
    reducers: {
        /**
         * Update the page's tracking campaign name
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        // eslint-disable-next-line id-length
        setPageTrackingCampaignName: (state, action) => {
            state.pageTrackingCampaignName = action.payload;
        },
        /**
         * Update whether the browser is desktop or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsMobile: (state, action) => {
            state.isMobile = action.payload;
        },
        /**
         * Update whether the browser is desktop or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsDesktop: (state, action) => {
            state.isDesktop = action.payload;
        },
        /**
         * Update whether the browser is desktop small or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsDesktopSmall: (state, action) => {
            state.isDesktopSmall = action.payload;
        },
        /**
         * Update with current scroll position
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setScrollPosition: (state, action) => {
            state.scrollPosition = action.payload;
        },
        /**
         * Update with current selected promo
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setSelectedPromo: (state, action) => {
            state.selectedPromo = action.payload;
        },
        /**
         * Update with current map markers
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setMoodIndex: ( state, action ) => {
            localStorage.setItem( COOKIE_MOOD, action.payload );
            state.moodIndex = action.payload;
        },
        /**
         * Update with current map markers
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setMapMarkers: (state, action) => {
            state.mapMarkers = action.payload;
        },
        /**
         * Update with current map markers
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setInteractiveMap: (state, action) => {
            state.interactiveMap = action.payload;
        },
        /**
         * Update with current map markers
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setInteractiveMapActivePin: (state, action) => {
            state.interactiveMapActivePin = action.payload;
        },
        /**
         * Update with current map markers
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        addMapMarker: (state, action) => {
            state.mapMarkers.push(action.payload);
        },
        /**
         * Update with if the map is open or not
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setMapIsOpen: (state, action) => {
            state.mapIsOpen = action.payload;
        },
        /**
         * triggers a dataLayer push when the device orientation changes
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setOrientation: (state, action) => {
            dataLayerPush('orientation', {
                // eslint-disable-next-line camelcase
                orientation_change: action.payload
            });
            state.orientation = action.payload;
        },
        /**
         * Update if we should stick the nav bar to the top for the browser
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setIsNavSticky: (state, action) => {
            document.body.dataset.sticky = action.payload;
            state.isNavSticky = action.payload;
        },
        /**
         * Update if the user is at the bottom of the page
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setPageBottom: (state, action) => {
            state.isAtPageBottom = action.payload;
        },
        /**
         * Set footer height so we place MoodSwitcherRender in the correct place
         * once the user is at the bottom of the page
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setFooterHeight: (state, action) => {
            state.footerHeight = action.payload;
        },
        /**
         * Update skip intro to true and also set the cookie
         *
         * @param {object} state - The current state of the store
         * @param {object} action - The action object, the payload should be a
         */
        setSkipIntro: ( state, action ) => {
            localStorage.setItem( COOKIE_INTRO, 'true' );
            state.skipIntro = action.payload;
        },
    }
});

export const getMapMarkers = (state) => state.ui.mapMarkers;

export const {
    // eslint-disable-next-line id-length
    setPageTrackingCampaignName,
    setOrientation,
    setIsMobile,
    setIsDesktop,
    setIsDesktopSmall,
    setScrollPosition,
    setSelectedPromo,
    setMoodIndex,
    setInteractiveMap,
    setInteractiveMapActivePin,
    setMapMarkers,
    addMapMarker,
    setMapIsOpen,
    setIsNavSticky,
    setPageBottom,
    setFooterHeight,
    setSkipIntro
} = uiSlice.actions;

export default uiSlice.reducer;
