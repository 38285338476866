import { getStorage } from 'common/helpers/local-storage';
import { DEFAULT_LANG } from 'common/constants';

/**
 * @example
 * import i18n from 'common/i18n';
 * i18n.lookup('label...')
 */
class Translator {
    constructor() {
        const reqLanguage = getStorage('req_language', true);
        this.language = reqLanguage || DEFAULT_LANG;
        this.hasTranslations = false;

        if (window?.PULSE?.I18N) {
            const translationsData = window.PULSE.I18N;
            this.hasTranslations = true;
            if (this.language !== translationsData.language) {
                if (typeof translationsData.language === 'undefined') {
                    console.warn(
                        `Language mismatch! Using ${translationsData.language}`
                    );
                }
                this.language = translationsData.language;
            }

            this.translations = translationsData.translations || {};
            this.hasTranslations = true;
        } else {
            this.hasTranslations = false;
            this.translations = {};
        }
    }

    /**
     * Lookup a translation and if passed, replace some keys with values
     *
     * @param {string} key - The translation key to lookup
     * @param {object} replaceMap - Keys to replace in the string
     * @returns {string} - The translation value or the key if not found
     */
    lookup(key, replaceMap = {}) {
        if (key && replaceMap) {
            let mapping = this.lookupKey(key);

            for (let replaceKey in replaceMap) {
                if ({}.hasOwnProperty.call(replaceMap, replaceKey)) {
                    let regExp = new RegExp('\\${' + replaceKey + '}', 'gi'); //eslint-disable-line prefer-template
                    mapping = mapping.replace(regExp, replaceMap[replaceKey]);
                }
            }

            return mapping;
        }

        return '';
    }

    /**
     * Directly fetch a key's value from the translation data
     *
     * @param {string} key - The translation key to fetch
     * @returns {string} - The translation value or the key if not found
     */
    lookupKey(key) {
        if (this.hasTranslations) {
            return this.translations[key] || key;
        }

        return key;
    }
}

export default new Translator();
