import { h } from 'preact';

export const ACCOUNT = 'expedia-hyatt-hotels',
    DEFAULT_LANG = 'EN',
    SITE_DISPLAY_NAME = 'Expedia Hyatt Hotels',
    COOKIE_NAME = 'expedia-cookies-accepted',
    COOKIE_INTRO = 'expedia-hyatt-skip-intro',
    COOKIE_MOOD = 'hyatt_mood',
    KEY_CODES = {
        ENTER: 13,
        ESCAPE: 27,
        ARROW_KEY_LEFT: 37,
        ARROW_KEY_RIGHT: 39,
        SPACE: 32
    },
    HTTP_STATUS_CODES = {
        OK: 200, // eslint-disable-line id-length
        UNAUTHORIZED: 401
    },
    TIME = {
        ONE_DAY_IN_MS: 86400000,
        ONE_MINUTE_IN_MS: 60000,
        ONE_SECOND_IN_MS: 1000,
        ONE_MINUTE_IN_SECS: 60,
        ONE_HOUR_IN_MINS: 60,
        ONE_DAY_IN_HOURS: 24,
        ONE_HOUR_IN_SECONDS: 3600,
        ACST_HOUR_OFFSET: 9.5
    },
    CLASSES = {
        U_HIDE: 'u-hide',
        IS_HOMEPAGE: 'is-homepage',
        IS_LOADING: 'is-loading',
        IS_ACTIVE: 'is-active',
        IS_LOADED: 'is-loaded',
        IS_EMPTY: 'is-empty',
        IS_SHOWING: 'is-showing',
        IS_STICKY: 'is-sticky',
        NO_SCROLL: 'no-scroll',
        OVERFLOW_HIDDEN: 'u-overflow-hidden'
    },
    IMAGE_TAGS = {
        mobileDark: 'mobile-dark',
        mobileLight: 'mobile-light',
        desktopDark: 'desktop-dark',
        desktopLight: 'desktop-light'
    },
    BREAKPOINTS = {
        MOBILE_SMALL: 375,
        MOBILE_MEDIUM: 425,
        MOBILE: 640,
        TABLET: 840,
        DESKTOP_SMALL: 1024,
        DESKTOP: 1200,
        DESKTOP_MEDIUM: 1360,
        DESKTOP_WIDE: 1440,
        DESKTOP_FULLSCREEN: 1520,
        DESKTOP_FULLSCREEN_LARGE: 2000
    },
    MQUERY = {
        MOBILE_SMALL: `(min-width: ${BREAKPOINTS.MOBILE_SMALL}px)`,
        MOBILE_MEDIUM: `(min-width: ${BREAKPOINTS.MOBILE_MEDIUM}px)`,
        MOBILE: `(min-width: ${BREAKPOINTS.MOBILE}px)`,
        TABLET: `(min-width: ${BREAKPOINTS.TABLET}px)`,
        DESKTOP_SMALL: `(min-width: ${BREAKPOINTS.DESKTOP_SMALL}px)`,
        DESKTOP: `(min-width: ${BREAKPOINTS.DESKTOP}px)`,
        DESKTOP_MEDIUM: `(min-width: ${BREAKPOINTS.DESKTOP_MEDIUM}px)`,
        DESKTOP_WIDE: `(min-width: ${BREAKPOINTS.DESKTOP_WIDE}px)`,
        DESKTOP_FULLSCREEN: `(min-width: ${BREAKPOINTS.DESKTOP_FULLSCREEN}px)`,
        DESKTOP_FULLSCREEN_LARGE: `(min-width: ${BREAKPOINTS.DESKTOP_FULLSCREEN_LARGE}px)`
    },
    MARKER_TYPES = [
        'roadtrip-start',
        'locations',
        'hotels',
        'experiences',
        'airports',
        'restaurants'
    ],
    EXPEDIA_DOMAINS = {
        en: 'https://www.expedia.com',
        'en-GB': 'https://www.expedia.co.uk',
        'fr-FR': 'https://www.expedia.fr',
        'de-DE': 'https://www.expedia.de',
        'es-MX': 'https://www.expedia.mx',
        default: 'https://www.expedia.com'
    },
    PATHS = {
        blog: '/blog/',
        regions: '/regions/',
        search: '/search/',
        themes: '/themes/',
        video: '/video/',
        videoRoadtrip: '/video/roadtrip/'
    },
    LOBS = {
        stays: 'HOTELS',
        flights: 'FLIGHTS',
        cars: 'CARS',
        experiences: 'ACTIVITIES'
    },
    TABS = {
        stays: 'stays',
        flights: 'flights',
        cars: 'cars',
        experiences: 'experiences'
    },
    TAGS = {
        hasNoAirport: 'has-no-airport',
        hasNoHotel: 'has-no-hotel'
    },
    TYPES = {
        airports: 'airports',
        videos: 'videos',
        hotels: 'hotels',
        locations: 'locations',
        experiences: 'experiences',
        restaurants: 'restaurants',
        roadtripStart: 'roadtrip-start'
    },
    TRIP_LIST = {
        videos: 'triplist-videos',
        hotels: 'triplist-hotels',
        locations: 'triplist-locations',
        experiences: 'triplist-experiences'
    };
