import debounce from 'lodash/debounce';
import store from 'common/store/store';
import {
    setIsDesktop,
    setIsDesktopSmall,
    setOrientation,
    setIsNavSticky,
    setPageBottom,
    setFooterHeight
} from 'common/store/ui-slice';
import { CLASSES } from 'common/constants';

import { BREAKPOINTS } from 'common/constants';

/**
 *
 */
export function setupPage() {

    // to place MoodSwitcherRender in the correct position
    // once the user is at the bottom of the page
    const updateFooterHeight = () => {
        const footer = document.getElementById('footer');
        
        store.dispatch(
            setFooterHeight(footer ? footer.offsetHeight : 0)
        );
    };

    // check on first load
    updateFooterHeight();

    // We listen to the resize event so we update our dimensions
    window.addEventListener(
        'resize',
        debounce(() => {
            const deviceWidth = Math.max(
                document.documentElement.clientWidth,
                window.innerWidth || 0
            );
            const deviceHeight = Math.max(
                document.documentElement.clientHeight,
                window.innerHeight || 0
            );

            // update on resize
            updateFooterHeight();

            store.dispatch(
                setIsDesktop(window.innerWidth > BREAKPOINTS.TABLET)
            );
            store.dispatch(
                setIsDesktopSmall(window.innerWidth > BREAKPOINTS.DESKTOP_SMALL)
            );
            store.dispatch(
                setOrientation(
                    deviceWidth > deviceHeight ? 'Landscape' : 'Portrait'
                )
            );
        })
    );

    if ( location.pathname === '/' ) {
        document.body.classList.add( CLASSES.IS_HOMEPAGE );
    }

    document.addEventListener('scroll', () => {
        store.dispatch(setIsNavSticky(window.scrollY > 0));

        // Check if user has scrolled to the bottom of the page
        // so we place MoodSwitcherRender in the correct position
        store.dispatch(
            setPageBottom(window.innerHeight + window.scrollY >= document.documentElement.scrollHeight)
        );

    });
}
