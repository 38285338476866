/**
 * Gets the currency symbol from its string label version
 *
 * @param {string} currency - currency name, e.g. GBP
 * @returns {string} currency symbol
 */
export function getCurrencySymbol(currency) {
    const CURRENCIES = {
        USD: {
            symbol: '$',
            pos: 'left'
        },
        GBP: {
            symbol: '£',
            pos: 'left'
        },
        EUR: {
            symbol: '€',
            pos: 'right'
        }
    };
    return CURRENCIES[currency] || '';
}

/**
 *
 * @param lang
 */
export function getCountryByLang(lang) {
    switch (lang) {
        case 'en-GB':
            return 'uk';
        case 'de-DE':
            return 'de';
        case 'es-MX':
            return 'es';
        case 'fr-FR':
            return 'fr';
        default:
            return 'us';
    }
}

/**
 * Determines whether user's device is iOS
 * Taken from https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
 *
 * @param {object} navigator - window.navigator object (falls back to empty object if not found)
 * @returns {boolean} true if device is iOS
 */
export function checkIsIOS(navigator = {}) {
    const platform = (navigator && navigator.platform) || '';
    const userAgent = (navigator && navigator.userAgent) || '';

    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(platform) ||
        // iPad on iOS 13 detection
        (userAgent.includes('Mac') && 'ontouchend' in document)
    );
}
