// @ts-nocheck
//eslint-disable
//<BROWSERSYNCPROXY>
//eslint-enable

const paths = [
    //eslint-disable
    //<PATHS>
    //eslint-enable
].map((path) => {
    path.cdnPath = path.cdnPath.replace(
        '{$RESOURCES_VERSION$}',
        window.RESOURCES_VERSION
    );
    return path;
});

/**
 * @function
 * @returns {any} environment
 */
function checkEnvironment() {
    const location = window.location.hostname;
    let environment;

    for (let i = 0; i < paths.length; i++) {
        const path = paths[i];

        if (
            typeof browsersyncProxy !== 'undefined' &&
            // eslint-disable-next-line no-useless-escape
            (location === 'localhost' ||
                /^(?=.*[^\.]$)((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.?){4}$/gim.test(
                    location
                ))
        ) {
            browsersyncProxy = browsersyncProxy.replace('https://', ''); // eslint-disable-line no-undef
            const envIndex = paths.findIndex((appPath) => {
                // eslint-disable-line no-loop-func
                return appPath.domain.indexOf(browsersyncProxy) > -1; // eslint-disable-line no-undef
            });
            environment = paths[envIndex];
            // Override to https://localhost:4040/ when using browsersync
            environment.cdn = `${window.location.origin}/`;
            break;
        } else if (Array.isArray(path.domain)) {
            const foundDomain = _checkDomains(path.domain, location);

            if (foundDomain) {
                path.domain = foundDomain;
                environment = path;
                break;
            }
        } else if (
            location === path.domain ||
            location.match(new RegExp(path.domain))
        ) {
            environment = path;
            break;
        }
    }

    if (!environment) {
        console.error('There are no app.paths defined for this domain');
    }

    // Combine cdnPath with current domain, to get a absolute CDN url
    if (!environment.cdn) {
        environment.cdn = `${window.location.protocol}//${environment.domain}${environment.cdnPath}`;
    }

    return environment;
}

/**
 * Check Domains
 *
 * Given an array of domains and location hostame it will check if any match
 *
 * @param {Array} domains - Array of domains from a paths blob
 * @param {string} location - window.location.hostname
 * @returns {?string} - The found domain or null
 */
const _checkDomains = (domains, location) => {
    // eslint-disable-next-line id-length
    for (let j = 0; j < domains.length; j++) {
        const domain = domains[j];
        if (location === domain || location.match(new RegExp(domain))) {
            return domain;
        }
    }

    return null;
};

export default checkEnvironment();
