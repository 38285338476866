/**
 * Range of local storage utility methods for use across JavaScript builds
 */

/**
 * Set a cookie with the passed configuration
 *
 * @param {string} name Name of storage object
 * @param {string} data Data to store
 * @param {number|Date} [expiry] Number of days before expiry or a dete object giving a specific date
 * @param {string} [domain] the domain of the page to set the cookie for, defaults to hostname
 * @param {string} [sameSite] The samesite property to set if using a cookie
 * @param {boolean} [secure] Set "Secure" if using a cookie
 */
function setCookie(
    name,
    data,
    expiry,
    domain,
    sameSite = 'Lax',
    secure = true
) {
    let exdate = new Date();
    if (expiry instanceof Date) {
        exdate = expiry;
    } else {
        exdate.setDate(exdate.getDate() + expiry);
    }
    const cValue = `${escape(data)}${
        expiry === null ? '' : `; expires='${exdate.toUTCString()}`
    }';`;
    const domainValue = domain
        ? ` domain='${domain || window.location.host};`
        : '';
    document.cookie = `${name}=${cValue} ${domainValue} path=/; SameSite=${sameSite}; ${
        secure ? 'Secure' : ''
    }`;
}

/**
 * Set given data in storage with either cookies or localStorage
 *
 * @param {string} name Name of storage object
 * @param {string} data Data to store
 * @param {number|Date} [expiry] Number of days before expiry or a dete object giving a specific date
 * @param {boolean} [useCookie] True if local storage should not be used
 * @param {string} [domain] the domain of the page to set the cookie for, defaults to hostname
 * @param {string} [sameSite] The samesite property to set if using a cookie
 * @param {boolean} [secure] Set "Secure" if using a cookie
 */
function setStorage(
    name,
    data,
    expiry,
    useCookie = false,
    domain,
    sameSite = 'Lax',
    secure = true
) {
    if (
        !useCookie &&
        'localStorage' in window &&
        window.localStorage !== null
    ) {
        window.localStorage.setItem(name, data);
        return;
    }

    setCookie(name, data, expiry, domain, sameSite, secure);
}

/**
 * @param {any} name -
 * @returns {any} -
 */
function getCookie(name) {
    let i,
        cookieName,
        cookieValue,
        ARRcookies = document.cookie.split(';');
    for (i = 0; i < ARRcookies.length; i++) {
        cookieName = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
        cookieValue = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
        cookieName = cookieName.replace(/^\s+|\s+$/g, '');
        if (cookieName === name) {
            return unescape(cookieValue);
        }
    }

    return null;
}

/**
 * Gets requested data from either cookies or localStorage
 *
 * @public
 * @param {string} name Name of storage object
 * @param {boolean} [useCookie] True if local storage should not be used
 * @returns {string} Data retrieved from stored object
 */
function getStorage(name, useCookie = false) {
    if (
        !useCookie &&
        'localStorage' in window &&
        window.localStorage !== null
    ) {
        return window.localStorage.getItem(name);
    }

    return getCookie(name);
}

/**
 * @param {any} name -
 * @param {any} domain -
 */
function removeCookie(name, domain) {
    setStorage(encodeURIComponent(name), '', -1, true, domain);
}

/**
 * Removes requested data from  either cookies or localStorage
 *
 * @public
 * @param {string} name Name of storage object
 * @param {boolean} [useCookie] True if local storage should not be used
 * @param {string} [domain] the domain the cookie is stored under, defaults to hostname
 */
function clearStorage(name, useCookie, domain) {
    if (
        !useCookie &&
        'localStorage' in window &&
        window.localStorage !== null
    ) {
        window.localStorage.removeItem(name);
        return;
    }

    removeCookie(name, domain);
}

export { setStorage, getStorage, clearStorage, removeCookie };
